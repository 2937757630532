<template>
  <div id="cleanRoomHumidity">
    <el-dialog
      :title="cleanRoomHumidityFormTitle"
      width="1200px"
      :visible.sync="cleanRoomHumidityDialogVisible"
      :close-on-click-modal="false"
      @close="cleanRoomHumidityDialogClose"
    >
      <el-form
        ref="cleanRoomHumidityFormRef"
        :model="cleanRoomHumidityForm"
        :rules="cleanRoomHumidityFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="测试日期" prop="testDate">
              <el-date-picker v-model="cleanRoomHumidityForm.testDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="测试人数" prop="testNumber">
              <el-input v-model="cleanRoomHumidityForm.testNumber" placeholder="请输入测试人数" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="温湿度测试仪器" prop="humidityInstrument">
              <el-input v-model="cleanRoomHumidityForm.humidityInstrument" placeholder="请输入温湿度测试仪器" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="温度仪器编号" prop="humidityNo">
              <el-input v-model="cleanRoomHumidityForm.humidityNo" placeholder="请输入温度仪器编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="压差计测试仪器" prop="pressureInstrument">
              <el-input v-model="cleanRoomHumidityForm.pressureInstrument" placeholder="请输入压差计测试仪器" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="压差计仪器编码" prop="pressureNo">
              <el-input v-model="cleanRoomHumidityForm.pressureNo" placeholder="请输入压差计仪器编码" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="cleanRoomHumidityFormTitle !== '洁净室（区）温湿度、静压差监测记录详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="humidityDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="roomName"
              title="房间名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="temperature"
              title="温度"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="relativeHumidity"
              title="相对湿度"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="staticPressureDifference"
              title="静压差"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="position"
              title="相对位置"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="conclusion"
              title="测试结论"
              :edit-render="{name: '$select', options: resultList, props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column v-if="cleanRoomHumidityFormTitle !== '洁净室（区）温湿度、静压差监测记录详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="总结论" prop="generalConclusion">
              <el-input
                v-model="cleanRoomHumidityForm.generalConclusion"
                placeholder="请输入总结论"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="检测人" prop="inspector">
              <el-input v-model="cleanRoomHumidityForm.inspector" placeholder="请输入检测人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测时间" prop="detectionDate">
              <el-date-picker v-model="cleanRoomHumidityForm.detectionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="cleanRoomHumidityForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复核时间" prop="reviewDate">
              <el-date-picker v-model="cleanRoomHumidityForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cleanRoomHumidityDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="cleanRoomHumidityFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="cleanRoomHumidityPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="testDate" label="测试日期" />
      <el-table-column prop="testNumber" label="测试人数" />
      <el-table-column prop="humidityInstrument" label="温湿度测试仪器" />
      <el-table-column prop="humidityNo" label="温度测试仪器编号" />
      <el-table-column prop="pressureInstrument" label="压差计测试仪器" />
      <el-table-column prop="pressureNo" label="压差计测试仪器编码" />
      <el-table-column prop="generalConclusion" label="总结论" />
      <el-table-column prop="inspector" label="检测人" />
      <el-table-column prop="detectionDate" label="检测时间" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column prop="reviewDate" label="复核时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="cleanRoomHumidityPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addCleanRoomHumidity, deleteCleanRoomHumidity, updateCleanRoomHumidity, selectCleanRoomHumidityInfo, selectCleanRoomHumidityList } from '@/api/quality/cleanRoomHumidityPressure'

export default {
  data () {
    return {
      cleanRoomHumidityDialogVisible: false,
      cleanRoomHumidityFormTitle: '',
      cleanRoomHumidityForm: {
        id: '',
        testDate: '',
        testNumber: '',
        humidityInstrument: '',
        humidityNo: '',
        pressureInstrument: '',
        pressureNo: '',
        generalConclusion: '',
        inspector: '',
        detectionDate: '',
        reviewer: '',
        reviewDate: '',
        humidityDetailJsom: ''
      },
      cleanRoomHumidityFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      cleanRoomHumidityPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      },
      humidityDetailList: [],
      resultList: [
        { label: '符合规定', value: '1' },
        { label: '不符合规定', value: '2' }
      ]
    }
  },
  created () {
    selectCleanRoomHumidityList(this.searchForm).then(res => {
      this.cleanRoomHumidityPage = res
    })
  },
  methods: {
    cleanRoomHumidityDialogClose () {
      this.$refs.cleanRoomHumidityFormRef.resetFields()
      this.humidityDetailList = []
      this.cleanRoomHumidityForm.id = ''
    },
    cleanRoomHumidityFormSubmit () {
      if (this.cleanRoomHumidityFormTitle === '洁净室（区）温湿度、静压差监测记录详情') {
        this.cleanRoomHumidityDialogVisible = false
        return
      }
      this.$refs.cleanRoomHumidityFormRef.validate(async valid => {
        if (valid) {
          this.cleanRoomHumidityForm.humidityDetailJsom = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.cleanRoomHumidityFormTitle === '新增洁净室（区）温湿度、静压差监测记录') {
            await addCleanRoomHumidity(this.cleanRoomHumidityForm)
          } else if (this.cleanRoomHumidityFormTitle === '修改洁净室（区）温湿度、静压差监测记录') {
            await updateCleanRoomHumidity(this.cleanRoomHumidityForm)
          }
          this.cleanRoomHumidityPage = await selectCleanRoomHumidityList(this.searchForm)
          this.cleanRoomHumidityDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.cleanRoomHumidityFormTitle = '新增洁净室（区）温湿度、静压差监测记录'
      this.cleanRoomHumidityDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCleanRoomHumidity(row.id)
        if (this.cleanRoomHumidityPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.cleanRoomHumidityPage = await selectCleanRoomHumidityList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.cleanRoomHumidityFormTitle = '修改洁净室（区）温湿度、静压差监测记录'
      this.cleanRoomHumidityDialogVisible = true
      this.selectCleanRoomHumidityInfoById(row.id)
    },
    handleInfo (index, row) {
      this.cleanRoomHumidityFormTitle = '洁净室（区）温湿度、静压差监测记录详情'
      this.cleanRoomHumidityDialogVisible = true
      this.selectCleanRoomHumidityInfoById(row.id)
    },
    selectCleanRoomHumidityInfoById (id) {
      selectCleanRoomHumidityInfo(id).then(res => {
        this.cleanRoomHumidityForm.id = res.id
        this.cleanRoomHumidityForm.testDate = res.testDate
        this.cleanRoomHumidityForm.testNumber = res.testNumber
        this.cleanRoomHumidityForm.humidityInstrument = res.humidityInstrument
        this.cleanRoomHumidityForm.humidityNo = res.humidityNo
        this.cleanRoomHumidityForm.pressureInstrument = res.pressureInstrument
        this.cleanRoomHumidityForm.pressureNo = res.pressureNo
        this.cleanRoomHumidityForm.generalConclusion = res.generalConclusion
        this.cleanRoomHumidityForm.inspector = res.inspector
        this.cleanRoomHumidityForm.detectionDate = res.detectionDate
        this.cleanRoomHumidityForm.reviewer = res.reviewer
        this.cleanRoomHumidityForm.reviewDate = res.reviewDate
        this.humidityDetailList = res.humidityDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCleanRoomHumidityList(this.searchForm).then(res => {
        this.cleanRoomHumidityPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCleanRoomHumidityList(this.searchForm).then(res => {
        this.cleanRoomHumidityPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCleanRoomHumidityList(this.searchForm).then(res => {
        this.cleanRoomHumidityPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
